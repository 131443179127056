// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_sb d_fp d_bG d_dv";
export var alignLeft = "t_rd d_fp d_bG d_dv";
export var alignDiscCenter = "t_sc d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_sd d_fr d_bH d_dx";
export var alignRight = "t_rf d_fr d_bH d_dx";
export var footerContainer = "t_sf d_dW d_bW";
export var footerContainerFull = "t_sg d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_sh d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_sj d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_sk d_bz d_bJ d_bN d_bL";
export var wide = "t_sl d_cy";
export var right = "t_sm d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_sn d_bC d_bP d_bJ";
export var badgeContainer = "t_sp d_bz d_bH d_bP";
export var badge = "t_sq";
export var padding = "t_sr d_c7";
export var end = "t_ss d_bH";
export var linkWrapper = "t_st d_dB";
export var link = "t_mZ d_dB";
export var colWrapper = "t_sv d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_sw d_bz d_bJ";
export var media = "t_sx d_bx d_dy";
export var itemRight = "t_sy";
export var itemLeft = "t_sz";
export var itemCenter = "t_sB";
export var exceptionWeight = "t_sC z_tJ";