// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "y_s6 d_bD";
export var storyRowWrapper = "y_hx d_hx d_bK";
export var storyLeftWrapper = "y_s7 d_bz d_bP";
export var storyWrapperFull = "y_s8 d_cD";
export var storyWrapperFullLeft = "y_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "y_mS d_hy";
export var storyLeftWrapperCenter = "y_s9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "y_tb d_hF";
export var storyHeader = "y_tc d_hD d_w d_cs";
export var storyHeaderCenter = "y_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "y_hB d_hB d_by d_dw";
export var storyBtnWrapper = "y_td d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "y_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "y_rj d_fg d_Z";
export var imageWrapperFull = "y_tf d_w d_H d_bf d_Z";
export var SubtitleSmall = "y_pd z_pd z_tg z_ts";
export var SubtitleNormal = "y_pf z_pf z_tg z_tt";
export var SubtitleLarge = "y_pg z_pg z_tg z_tv";
export var textLeft = "y_dv";
export var textCenter = "y_dw";
export var textRight = "y_dx";