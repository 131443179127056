// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sD d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sF d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sG d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sH d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sJ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sK d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sL d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_sM z_sM";
export var heroExceptionNormal = "v_sN z_sN";
export var heroExceptionLarge = "v_sP z_sP";
export var Title1Small = "v_sQ z_sQ z_tg z_th";
export var Title1Normal = "v_sR z_sR z_tg z_tj";
export var Title1Large = "v_sS z_sS z_tg z_tk";
export var BodySmall = "v_sT z_sT z_tg z_tz";
export var BodyNormal = "v_sV z_sV z_tg z_tB";
export var BodyLarge = "v_sW z_sW z_tg z_tC";