// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "C_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "C_fR d_fR d_bz d_bJ";
export var menuDesign6 = "C_vq d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "C_vr d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "C_vs d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "C_vt d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "C_vv d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "C_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "C_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "C_vw";
export var navbarItem = "C_nb d_bx";
export var navbarLogoItemWrapper = "C_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "C_vx d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "C_vy d_gd d_by d_Z d_bs";
export var burgerToggle = "C_vz d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "C_vB d_gd d_by d_Z d_bs";
export var burgerInput = "C_vC d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "C_vD d_f3 d_w d_H";
export var burgerLine = "C_vF d_f1";
export var burgerMenuDesign6 = "C_vG d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "C_vH d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "C_vJ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "C_vK d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "C_vL d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "C_vM d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "C_vN d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "C_vP d_bC d_bP";
export var compact = "C_vQ";
export var navDivided = "C_vR";
export var staticBurger = "C_vS";
export var menu = "C_vT";
export var navbarDividedLogo = "C_vV";
export var nav = "C_vW";
export var fixed = "C_vX";